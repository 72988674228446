import { useParams } from 'react-router-dom';
import Dashboard, { DashboardRow, DashboardSection, DashboardSectionTitle } from '@components/dashboard';
import { ROUTES } from '@constants/routes';
import { generateUrl } from '@routes/util';
import { AdminBreadcrumbs } from '@routes/admin-dashboard/AdminBreadcrumbs';
import { QUESTION } from '@constants/terminology';
import { QuestionManagement, ShowAs } from '../../components/admin-dashboard/questions/QuestionManagement';

export const AdminQuestionRoute = () => {
  const { initiativeId } = useParams<{ initiativeId: string }>();

  return (
    <div className='admin-dashboard'>
      <Dashboard>
        <DashboardRow>
          <AdminBreadcrumbs
            initiativeId={initiativeId}
            breadcrumbs={[
              {
                label: 'Admin Dashboard',
                url: generateUrl(ROUTES.ADMIN_DASHBOARD, { initiativeId }),
              },
              {
                label: QUESTION.CAPITALIZED_PLURAL,
              },
            ]}
          />
        </DashboardRow>
        <DashboardSectionTitle title={`${QUESTION.CAPITALIZED_SINGULAR} overrides`} />
        <DashboardSection>
          <QuestionManagement showAs={ShowAs.Page} initiativeId={initiativeId} pageSize={50} />
        </DashboardSection>
      </Dashboard>
    </div>
  );
};
