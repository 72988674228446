import Dashboard, { DashboardRow, DashboardSection } from '@components/dashboard';
import { useNavigate } from 'react-router-dom-v5-compat';
import { Button } from 'reactstrap';
import { BulkOnboardingImport } from './bulk-onboarding-import/BulkOnboardingImport';

export const BulkOnboardingImportContainer = (props: { initiativeId: string, userLimit: number }) => {
  const navigate = useNavigate();
  return (
    <Dashboard>
      <DashboardRow>
        <Button color='link' onClick={() => navigate(-1)}>
          <i className='fa fa-arrow-circle-left mr-2' /> Go back to previous page
        </Button>
      </DashboardRow>
      <DashboardSection
        icon='fal fa-users'
        headingStyle={4}
        title='Invite multiple users across your reporting levels'
        subtitle='Invite multiple users across the various platform reporting levels for your organisation.'
      >
        <div className='border-bottom' />
        <BulkOnboardingImport {...props} />
      </DashboardSection>
    </Dashboard>
  );
};
