import { SurveyUnitsAndCurrencyText } from '@components/survey-configuration/partials/SurveyUnitsAndCurrency';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { TableColumn } from '@components/survey/form/input/table/InputInterface';
import { DecimalType, ErrorDecimalType } from '@components/utr-decimal/utils';
import { QUESTION } from '@constants/terminology';
import { Option } from '@g17eco/molecules';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { UnitConfig } from '@models/surveyData';
import { isDefined } from '@utils/index';
import { getCurrencyList, getUnitsForType, UnitOption, UnitTypes } from '@utils/units';
import { isSimpleNumericColumnType, isNumericValueType } from '@utils/universalTracker';
import { UtrvConfigCode, UtrvConfigValue } from './metric-override/contants';
import { isDefinedNumber } from '@utils/number';

export enum ConfigurationType {
  MetricOverrides = 'Metric overrides',
  InputOverrides = 'Input overrides',
  Tags = 'Assign tags',
}

export enum InputOverrideType {
  NumberScale = 'numberScale',
  Decimal = 'decimal',
  Unit = 'unit',
}

export enum InputOverrideFormType {
  SingleInput = 'single-input',
  Table = 'table',
}

export const GLOBAL_UPDATE_CODE = '__DATA__';

export const NON_NUMERIC_SELECTED_QUESTION_TOOLTIP =
  'Overrides can not be set for this input as it requires a numeric value';

export type InputOverrideDataType<T = string> = { value: T | undefined; isEnforced: boolean };

export type UnitConfigValueType = InputOverrideDataType & { isLocked: boolean };

export type UnitConfigType = Record<string, Record<string, UnitConfigValueType>>;

export type UnitTypeMapValue = {
  defaultValue: string | undefined;
  overriddenValue: string | undefined;
  isLocked: boolean;
};

export const getModalTitle = (selectedQuestions: Pick<BulkActionUtr, 'name'>[] | undefined) => {
  if (!selectedQuestions || !selectedQuestions.length) {
    return '';
  }
  if (selectedQuestions.length === 1) {
    return selectedQuestions[0].name ?? `${QUESTION.CAPITALIZED_SINGULAR} management`;
  }
  return `${QUESTION.CAPITALIZED_SINGULAR} management (multiple ${QUESTION.CAPITALIZED_PLURAL})`;
};

export const getUnitLabel = (unitType: string) => {
  if (unitType === UnitTypes.numberScale) {
    return 'Number scale';
  }
  if (unitType in SurveyUnitsAndCurrencyText) {
    return SurveyUnitsAndCurrencyText[unitType as keyof UnitConfig].title;
  }
  return unitType;
};

const addUnitType = ({
  unitTypeMap,
  unitType,
  unit,
  unitInput,
  isLocked = true, // treat undefined value as locked, checkbox is off
}: {
  unitTypeMap: Map<string, UnitTypeMapValue>;
  unitType: string | undefined;
  unit: string | undefined;
  unitInput: string | undefined;
  isLocked: boolean | undefined;
}) => {
  if (!unitType || unitType === 'currency' || !unit) {
    return;
  }
  const existing = unitTypeMap.get(unitType);
  const overriddenValue = existing?.overriddenValue ?? unitInput; // set the first overridden value found for each unitType, skip the rest
  const defaultValue = existing?.defaultValue ?? unit;
  // for override modal visualization
  // if at least one question is unlocked (isLocked = false | checkbox is ticked), then ignore the rest
  // => initial checkbox is ticked
  unitTypeMap.set(unitType, {
    defaultValue,
    overriddenValue,
    isLocked: existing && !existing.isLocked ? existing.isLocked : isLocked,
  });
};

const addNumberScale = ({
  unitTypeMap,
  numberScale,
  numberScaleInput,
  isLocked = true, // treat undefined value as locked, checkbox is off
}: {
  unitTypeMap: Map<string, UnitTypeMapValue>;
  numberScale: string | undefined;
  numberScaleInput: string | undefined;
  isLocked: boolean | undefined;
}) => {
  const existing = unitTypeMap.get(UnitTypes.numberScale);
  const overriddenValue = existing?.overriddenValue ?? numberScaleInput;
  unitTypeMap.set(UnitTypes.numberScale, {
    defaultValue: existing?.defaultValue ?? numberScale, // set the first default value found, skip the rest
    overriddenValue,
    isLocked: existing && !existing.isLocked ? existing.isLocked : isLocked,
  });
};

const getTableUnitTypeMap = (selectedQuestion: BulkActionUtr, overriddenQuestions: InitiativeUniversalTracker[]) => {
  const columns = selectedQuestion.valueValidation?.table?.columns;
  if (!columns || !columns.length) {
    return {};
  }
  const overriddenColumns = overriddenQuestions.find((item) => item.universalTrackerId === selectedQuestion._id)
    ?.valueValidation?.table?.columns;

  return columns.reduce((acc, col) => {
    const columnMap = new Map();
    const overriddenCol = overriddenColumns?.find((item) => item.code === col.code);
    const { unit, unitType, numberScale } = col;
    const { numberScaleInput, unitInput, unitLocked, numberScaleLocked } = overriddenCol ?? {};
    if (isSimpleNumericColumnType(col)) {
      addNumberScale({
        unitTypeMap: columnMap,
        numberScale,
        numberScaleInput,
        isLocked: numberScaleLocked,
      });
      addUnitType({ unitTypeMap: columnMap, unitType, unit, unitInput, isLocked: unitLocked });
      acc[col.code] = columnMap;
    }
    return acc;
  }, {} as Record<string, Map<string, UnitTypeMapValue>>);
};

export const getUnitTypeMap = ({
  selectedQuestions,
  overriddenQuestions,
  inputType,
}: {
  selectedQuestions: BulkActionUtr[];
  overriddenQuestions: InitiativeUniversalTracker[];
  inputType: InputOverrideFormType;
}) => {
  if (inputType === InputOverrideFormType.Table) {
    return getTableUnitTypeMap(selectedQuestions[0], overriddenQuestions);
  }

  const getInitiativeUtr = (_id: string) => overriddenQuestions.find((item) => item.universalTrackerId === _id);

  const processNumericQuestions = (unitTypeMap: Map<string, UnitTypeMapValue>, question: BulkActionUtr) => {
    const { unit, unitType, numberScale, _id } = question;
    const initiativeUtr = getInitiativeUtr(_id);
    const { numberScaleInput, unitInput, unitLocked, numberScaleLocked } = initiativeUtr ?? {};

    addNumberScale({
      unitTypeMap,
      numberScale,
      numberScaleInput,
      // old data: if has override but lock is undefined => false
      isLocked: numberScaleInput && numberScaleLocked === undefined ? false : numberScaleLocked,
    });
    addUnitType({
      unitTypeMap,
      unitType,
      unit,
      unitInput,
      // old data: if has override but lock is undefined => false
      isLocked: unitInput && unitLocked === undefined ? false : unitLocked,
    });
  };

  const processTableQuestions = (unitTypeMap: Map<string, UnitTypeMapValue>, question: BulkActionUtr) => {
    const overriddenTable = getInitiativeUtr(question._id)?.valueValidation?.table;
    question.valueValidation?.table?.columns.forEach((col) => {
      if (isSimpleNumericColumnType(col)) {
        const overriddenColumn = overriddenTable?.columns.find((c) => c.code === col.code);
        const { unitType, unit, numberScale } = col;
        const { numberScaleInput, unitInput, unitLocked, numberScaleLocked } = overriddenColumn ?? {};
        addNumberScale({
          unitTypeMap,
          numberScale,
          numberScaleInput,
          isLocked: numberScaleInput && numberScaleLocked === undefined ? false : numberScaleLocked,
        });
        addUnitType({
          unitTypeMap,
          unitType,
          unit,
          unitInput,
          isLocked: unitInput && unitLocked === undefined ? false : unitLocked,
        });
      }
    });
  };

  const unitTypeMap = selectedQuestions.reduce((acc, question) => {
    switch (question.valueType) {
      case UtrValueType.NumericValueList:
      case UtrValueType.Number:
      case UtrValueType.Percentage:
        processNumericQuestions(acc, question);
        break;
      case UtrValueType.Table:
        processTableQuestions(acc, question);
        break;
      default:
        break;
    }

    return acc;
  }, new Map<string, UnitTypeMapValue>());

  return { [GLOBAL_UPDATE_CODE]: unitTypeMap };
};

const createInitialUnitConfig = (unitTypeMap: Map<string, UnitTypeMapValue>) => {
  return Array.from(unitTypeMap).reduce((acc, [key, value]) => {
    acc[key] = {
      value: value.overriddenValue ?? value.defaultValue,
      isEnforced: isDefined(value.overriddenValue),
      isLocked: value.isLocked,
    };
    return acc;
  }, {} as Record<string, UnitConfigValueType>);
};

export const getInitialUnitConfig = ({
  inputType,
  tableColumns,
  unitTypeMap,
}: {
  inputType: InputOverrideFormType;
  tableColumns: TableColumn[];
  unitTypeMap: Record<string, Map<string, UnitTypeMapValue>>;
}) => {
  if (inputType === InputOverrideFormType.SingleInput) {
    return { [GLOBAL_UPDATE_CODE]: createInitialUnitConfig(unitTypeMap[GLOBAL_UPDATE_CODE]) };
  }
  // single table question
  return tableColumns.reduce((acc, col) => {
    const columnMap = unitTypeMap[col.code];
    if (columnMap) {
      acc[col.code] = createInitialUnitConfig(columnMap);
    }
    return acc;
  }, {} as UnitConfigType);
};

// check if decimal has changed 
export const hasDataChanged = <T = string>(
  defaultValue: Record<string, InputOverrideDataType<T>>,
  value: Record<string, InputOverrideDataType<T>>
) => {
  return Object.keys(defaultValue).some(
    (key) => defaultValue[key].value !== value[key].value || defaultValue[key].isEnforced !== value[key].isEnforced
  );
};

/*
  defaultUnitConfig and unitConfig example:
  single simple question / multi questions: 
    { __DATA__: { numberScale: { value: 'hundreds', isEnforced: true, isLocked: true } }
  single table question:
    { 
      colA: { numberScale: { value: 'hundreds', isEnforced: true, isLocked: true },
      colB: { numberScale: { value: 'thousands', isEnforced: false, isLocked: false }
    }
*/

export const hasUnitDataChanged = (
  defaultUnitConfig: Record<string, Record<string, UnitConfigValueType>>,
  unitConfig: Record<string, Record<string, UnitConfigValueType>>
) => {
  // code: __DATA__ or columnCode
  return Object.keys(defaultUnitConfig).some((code) => {
    const defaultData = defaultUnitConfig[code];
    const data = unitConfig[code];
    return Object.keys(defaultData).some(
      (unitType) =>
        defaultData[unitType].value !== data[unitType].value ||
        defaultData[unitType].isEnforced !== data[unitType].isEnforced ||
        defaultData[unitType].isLocked !== data[unitType].isLocked
    );
  });
};

export const validateData = <T = string>({
  error,
  data,
}: {
  data: Record<string, InputOverrideDataType<T>>;
  error?: ErrorDecimalType;
}) => {
  const hasError = error ? Object.values(error).some((err) => err) : false;
  return !hasError && Object.keys(data).every((key) => (data[key].isEnforced ? isDefined(data[key].value) : true));
};

export const validateUnitData = ({ data }: { data: Record<string, Record<string, UnitConfigValueType>> }) => {
  return Object.keys(data).every((key) => validateData({ data: data[key] }));
};

const getOptions = (type: string): UnitOption[] => {
  if (type === UnitTypes.currency) {
    return getCurrencyList();
  }
  if (type === UnitTypes.numberScale) {
    return getUnitsForType(UnitTypes.currency);
  }
  return getUnitsForType(type);
};

export const getUnitByTypeOptions = (type: string): Option<string>[] => {
  const options = getOptions(type);
  return options.map(({ abbr, singular }) => ({ label: `${singular} (${abbr})`, value: abbr }));
};

const transformData = <T = string>(data: Record<string, InputOverrideDataType<T>>) => {
  return Object.keys(data).reduce((acc, key) => {
    const { isEnforced, value } = data[key];
    acc[key] = isEnforced && value !== undefined ? value : null;
    return acc;
  }, {} as Record<string, T | null>);
};

const prepareUnitConfigData = ({ unitConfig, type }: { unitConfig: UnitConfigType; type?: InputOverrideType }) => {
  return Object.keys(unitConfig).reduce((acc, code) => {
    acc[code] = Object.keys(unitConfig[code]).reduce((data, unitType) => {
      const { isEnforced, value, isLocked } = unitConfig[code][unitType];
      const overrideNumberScaleOnly = type === InputOverrideType.NumberScale && unitType === UnitTypes.numberScale;
      const overrideUnitOnly = type === InputOverrideType.Unit && unitType !== UnitTypes.numberScale;
      
      if (!type || overrideNumberScaleOnly || overrideUnitOnly) {
        data[unitType] = isEnforced && value !== undefined ? value : '';
        const lockKey = unitType === InputOverrideType.NumberScale ? 'numberScaleLocked' : 'unitLocked';
        data[lockKey] = isEnforced && isLocked;
      }

      return data;
    }, {} as Record<string, string | boolean>);
    return acc;
  }, {} as Record<string, Record<string, string | boolean>>);
};

export const prepareUpdateData = ({
  decimal,
  unitConfig,
  type,
}: {
  decimal: DecimalType;
  unitConfig: UnitConfigType;
  type: InputOverrideType | undefined;
}) => {
  // update partially depending on type for multiple metrics
  switch (type) {
    case InputOverrideType.Decimal:
      return { transformedDecimal: transformData<number>(decimal) };
    case InputOverrideType.Unit:
    case InputOverrideType.NumberScale:
      return {
        transformedUnitConfig: prepareUnitConfigData({ unitConfig, type }),
      };
    // update all input overrides for single metric
    default:
      return {
        transformedDecimal: transformData<number>(decimal),
        transformedUnitConfig: prepareUnitConfigData({ unitConfig }),
      };
  }
};

export const getRootInitiativeMap = (data: InitiativeUniversalTracker[] | undefined) =>
  new Map<string, InitiativeUniversalTracker>(
    (data ?? []).map((utr: InitiativeUniversalTracker) => [utr.universalTrackerId, utr])
  );

type FilterInputOverrides = {
  initiativeUtr: InitiativeUniversalTracker | undefined;
  valueType: UtrValueType;
};

export const hasUnitOrNumScaleOverrides = ({
  initiativeUtr,
  valueType,
  field,
}: FilterInputOverrides & { field: 'unitInput' | 'numberScaleInput' }) => {
  if (isNumericValueType(valueType)) {
    return Boolean(initiativeUtr?.[field]);
  }
  if (valueType === UtrValueType.Table) {
    return initiativeUtr?.valueValidation?.table?.columns?.some((col) => col[field]);
  }
  return false;
};

export const hasDecimalOverrides = ({ initiativeUtr, valueType }: FilterInputOverrides) => {
  if (isNumericValueType(valueType)) {
    return isDefinedNumber(initiativeUtr?.valueValidation?.decimal);
  }
  if (valueType === UtrValueType.Table) {
    return initiativeUtr?.valueValidation?.table?.columns?.some((col) => isDefinedNumber(col.validation?.decimal));
  }
  return false;
};

export const hasOverriddenUtrvConfig = ({
  initiativeUtr,
  field,
}: {
  field: UtrvConfigCode;
  initiativeUtr: InitiativeUniversalTracker | undefined;
}) => {
  if (!initiativeUtr?.utrvConfig) {
    return false;
  }
  return [UtrvConfigValue.Optional, UtrvConfigValue.Required].includes(initiativeUtr.utrvConfig[field]);
};

export const iconMap = {
  evidenceRequired: { requiredIcon: 'fas fa-file', optionalIcon: 'fal fa-file', label: 'Evidence' },
  verificationRequired: { requiredIcon: 'fas fa-user-pen', optionalIcon: 'fal fa-user-pen', label: 'Verification' },
  noteRequired: { requiredIcon: 'fas fa-clipboard-list-check', optionalIcon: 'fal fa-clipboard-list-check', label: 'Explanation' },
  isPrivate: { requiredIcon: 'fas fa-eye-slash', optionalIcon: 'fal fa-eye', label: 'Privacy' },
};
