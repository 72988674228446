export enum BulkOnboardingImportSteps {
  DownLoadTemplate = 'Download Excel template',
  UploadFile = 'Upload completed Excel template',
  ReviewData = 'Review users and permissions',
}

export enum ValidateMessages {
  InvalidEmail = 'Invalid email address provided',
  InvalidCode = 'Subsidiary code unknown',
  DuplicatedInExistingInitiative = 'Duplicate: Have taken highest permissions',
  DuplicatedInvite = 'Duplicate invite: Will ignore this invite',
  MissingPermissions = 'Missing permissions',
}

