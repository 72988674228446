import Select, { MultiValue, createFilter, Props as BaseSelectProps, GroupBase, ActionMeta } from 'react-select';
import BaseCreatableSelect from 'react-select/creatable';
import { Input } from 'reactstrap';
import { Checkbox, CheckboxState } from '@g17eco/atoms';
import { DropdownIndicator, HiddenDropdownIndicator, Option, SelectStyleProps, getStyles } from './SelectFactory';
import { getFilterOption, WithString } from './utils';

export interface MultipleSelectProps<T = string | null>
  extends Pick<
      BaseSelectProps<Option<WithString<T>>, true, GroupBase<Option<WithString<T>>>>,
      'components' | 'placeholder' | 'menuPlacement' | 'isDisabled' | 'className'
    >,
    SelectStyleProps {
  options: Option<WithString<T>>[];
  onChange: (values: Option<WithString<T>>['value'][], actionMeta?: ActionMeta<Option<WithString<T>>>) => void;
  values: Option<WithString<T>>['value'][];
  noOptionsMessage?: string;
  hideCheckboxOnDisabled?: boolean;
  showSelectAll?: boolean;
  showSelectedOptions?: boolean;
  allowCustomOption?: boolean;
}

const getNoOptions = (title: string = 'No options are available') => {
  return [
    {
      value: 'no-options',
      label: title,
      isDisabled: true,
    },
  ];
};

const getToggleAllOption = <T = string | null,>(
  values: Option<WithString<T>>['value'][],
  options: Option<WithString<T>>[]
): Option<WithString<T>> => {
  const label = `${values.length ? 'Deselect' : 'Select'} all`;
  const status = !values.length
    ? CheckboxState.Unchecked
    : values.length === options.filter(({ isDisabled }) => !isDisabled).length
    ? CheckboxState.Checked
    : CheckboxState.Indeterminate;

  return {
    value: 'select-all',
    searchString: label,
    label: (
      <div className='d-flex align-items-center'>
        <Checkbox status={status} onChange={() => {}} className='mt-0 me-2 flex-shrink-0' />
        <span>{label}</span>
      </div>
    ),
  };
};

const getValue = <T = string | null>(selectedOption: Option<WithString<T>> | MultiValue<Option<WithString<T>>>) => {
  if (Array.isArray(selectedOption)) {
    const [first] = selectedOption as MultiValue<Option<WithString<T>>>;
    return first?.value;
  }

  return (selectedOption as Option<WithString<T>>).value;
};
export const MultipleSelect = <T = string | null,>({
  placeholder = 'Select',
  options: optionsProp,
  onChange: onChangeProp,
  values,
  isDisabled = false,
  className = '',
  menuPlacement,
  noOptionsMessage,
  hideCheckboxOnDisabled = true,
  showSelectAll = true,
  isFlexibleSize,
  isTransparent,
  showDropdownIndicator = true,
  showSelectedOptions = false,
  allowCustomOption = false,
  isMenuPortalTargetBody = false,
  components,
}: MultipleSelectProps<T>) => {

  const getLabel = ({ value, label, isDisabled }: Option<WithString<T>>) => {
    const checked = values.includes(value);
    return (
      <div className='d-flex align-items-center'>
        {isDisabled && hideCheckboxOnDisabled ? null : (
          <Input
            type='checkbox'
            disabled={isDisabled}
            checked={checked}
            onChange={() => {}}
            className='mt-0 me-2 flex-shrink-0'
          />
        )}
        <span>{label}</span>
      </div>
    );
  };

  const nonDisableOptions = optionsProp.filter(({ isDisabled }) => !isDisabled);

  const toggleAllOption = getToggleAllOption(values, nonDisableOptions);

  const options: Option<WithString<T>>[] =
    optionsProp.length === 0
      ? getNoOptions(noOptionsMessage)
      : [
          ...(showSelectAll ? [toggleAllOption] : []),
          ...optionsProp.map(({ value, searchString, label, isDisabled }) => ({
            value,
            searchString,
            label: getLabel({ value, label, isDisabled }),
            isDisabled,
          })),
        ];

  const selectedOptions = showSelectedOptions ? optionsProp.filter((op) => values.includes(op.value)) : [];

  const toggleValue = (value: Option<WithString<T>>['value']) => {
    const existed = values.some((v) => v === value);
    const newValues = existed ? values.filter((v) => v !== value) : [...values, value];
    onChangeProp(newValues);
  };

  const toggleAll = () => {
    const newValues = values.length ? [] : nonDisableOptions.map(({ value }) => value);
    onChangeProp(newValues);
  };

  const onChange = (newValue: MultiValue<Option<WithString<T>>>) => {
    if (!newValue) {
      return;
    }

    const value = getValue(newValue);
    if (!value) {
      return;
    }

    return value === toggleAllOption.value ? toggleAll() : toggleValue(value);
  };

  const onDefaultChange = (
    options: MultiValue<Option<WithString<T>>>,
    actionMeta?: ActionMeta<Option<WithString<T>>>
  ) => {
    const isToggleAll = options.some((op) => op.value === toggleAllOption.value);
    if (isToggleAll) {
      toggleAll();
      return;
    }

    const values = options.map((op) => op.value);
    onChangeProp(values, actionMeta);
  };

  const Component = allowCustomOption ? BaseCreatableSelect : Select;

  return (
    <Component<Option<WithString<T>>, true>
      menuPlacement={menuPlacement}
      className={className}
      placeholder={typeof placeholder === 'string' ? `${placeholder}${values.length ? ` (${values.length})` : ''}` : placeholder}
      components={{ DropdownIndicator: showDropdownIndicator ? DropdownIndicator : HiddenDropdownIndicator, ...components }}
      styles={getStyles<Option<WithString<T>>>({
        active: !!values.length,
        isMulti: true,
        isFlexibleSize,
        isTransparent,
        showSelectAll,
        isMenuPortalTargetBody,
      })}
      isMulti
      isDisabled={isDisabled}
      closeMenuOnSelect={false}
      filterOption={getFilterOption<Option<WithString<T>>>()}
      value={selectedOptions}
      hideSelectedOptions={false}
      options={options}
      onChange={showSelectedOptions ? onDefaultChange : onChange}
      isClearable={false}
      menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
    />
  );
};
