import { useGetSecondaryConnectionsQuery } from '@api/utrv';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import { QUESTION } from '@constants/terminology';
import { DashboardDivider } from '@g17eco/atoms';
import { getGroup } from '@g17eco/core';
import { UniversalTrackerPlain, UtrType, UtrValueType } from '@g17eco/types/universalTracker';
import {
  Calculation,
  CalculationType,
  UniversalTrackerConnection,
  CalculationUtr,
  VariableInfo,
  CalculationIntegrationUtr,
  CalculationData,
} from '@g17eco/types/utrv-connections';
import { useToggle } from '@hooks/useToggle';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CalculationWithUtrInfo } from './types';
import { SelectedConnection, useConnectionContext } from './ConnectionContext';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { SimpleTooltip } from '@g17eco/molecules';
import { getCalculations, getConvertedCalculationValue, getInputValue } from './utils';
import { getUnitText } from '@utils/units';

interface VariableProps {
  calculation: Calculation;
  variable: VariableInfo;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
}

const isCalculationIntegrationUtr = (
  utr: CalculationUtr | CalculationIntegrationUtr
): utr is CalculationIntegrationUtr => utr.type === UtrType.Generated;

interface PackInfo {
  utr: CalculationUtr | CalculationIntegrationUtr;
  calculation: Calculation;
}

const getPackInfo = ({ utr, calculation }: PackInfo) => {
  if (calculation.group) {
    return {
      logo: calculation.group.icon,
      shortName: calculation.group.name,
      colour: calculation.group.colour,
    };
  }

  if (isCalculationIntegrationUtr(utr)) {
    return utr.provider ? { logo: utr.provider.icon ?? utr.provider.logo, shortName: utr.provider.shortName } : undefined;
  }
  const standard = utr.type ? getGroup('standards', utr.type) : undefined;
  return standard ? { logo: standard.src, shortName: standard.shortName } : undefined;
};


const iconStyle: CSSProperties = {
  backgroundColor: '#4E7AE8FF',
  width: '16px',
  minWidth: '16px',
  height: '16px',
  borderRadius: '4px',
};

const RenderGroupImage = (props: PackInfo) => {

  const packInfo = getPackInfo(props);
  if (!packInfo) {
    return null;
  }

  const name = <div className='me-auto text-nowrap text-ThemeTextDark'>{packInfo.shortName}</div>;

  if (packInfo.logo) {
    return (
      <>
        <img src={packInfo.logo} alt={packInfo.shortName} height='16px' className='mr-1' />
        {name}
      </>
    );
  }

  if (packInfo.colour) {
    return (
      <>
        <i className='mr-1' style={iconStyle} />
        {name}
      </>
    );
  }

  return name;
}

const Variable = ({ calculation, variable, utrs, integrationUtrs }: VariableProps) => {
  const utr = (variable.integrationCode ? integrationUtrs : utrs).find((utr) => utr.code === variable.utrCode);
  if (!utr) {
    return null;
  }

  const inputTitle = utr.valueValidation?.table?.columns.find((column) => column.code === variable.valueListCode)?.name;
  const packInfo = getPackInfo({utr : utr, calculation : calculation});
  return (
    <div className='mt-1'>
      <div className='d-flex align-items-center'>
        <RenderGroupImage utr={utr} calculation={calculation} />
        <p className='flex-grow-1 text-right my-0 ms-3 text-truncate text-ThemeTextDark text-sm'>
          <SimpleTooltip text={utr.valueLabel}>{utr.valueLabel}</SimpleTooltip>
        </p>
      </div>
      {inputTitle ? (
        <>
          <p className='mb-0 mt-1 text-ThemeTextMedium text-sm text-right'>{inputTitle}</p>
        </>
      ) : null}
    </div>
  );
};

interface VariableCollapseProps {
  name: string;
  variable: VariableInfo;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
  calculation: Calculation;
}
const VariableCollapse = ({ calculation, name, variable, utrs, integrationUtrs }: VariableCollapseProps) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <div className='mt-2 d-flex justify-content-end align-items-center cursor-pointer' onClick={toggle}>
        <p className='mb-0 mr-1 text-ThemeTextMedium text-sm'>
          {QUESTION.CAPITALIZED_SINGULAR} {name.toUpperCase()}
        </p>
        <i
          className={classNames('fa-light text-ThemeIconSecondary', {
            'fa-caret-up': isOpen,
            'fa-caret-down': !isOpen,
          })}
        ></i>
      </div>
      <Collapse isOpen={isOpen}>
        <Variable calculation={calculation} variable={variable} utrs={utrs} integrationUtrs={integrationUtrs} />
      </Collapse>
    </>
  );
};

// {a} + {b} -> Metric A + Metric B
const transformFormula = (formula: string) => {
  return formula
    .toUpperCase()
    .replace(/{/g, ` ${QUESTION.CAPITALIZED_SINGULAR} `)
    .replace(/}/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
};

interface VariablesProps {
  calculation: Calculation;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
}
const Variables = ({ calculation, utrs, integrationUtrs }: VariablesProps) => {
  switch (calculation.type) {
    case CalculationType.Direct: {
      return (
        <Variable calculation={calculation} variable={calculation.variables[calculation.direct]} utrs={utrs} integrationUtrs={integrationUtrs} />
      );
    }
    case CalculationType.Formula: {
      const variables = calculation.variables;

      return (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='text-sm my-0 mt-2 text-ThemeTextMedium'>Calculation:</label>
            <p className='mb-0 text-ThemeTextDark text-sm'>{transformFormula(calculation.formula)}</p>
          </div>
          {Object.keys(variables).map((key) => (
            <VariableCollapse
              key={key}
              name={key}
              variable={variables[key]}
              utrs={utrs}
              calculation={calculation}
              integrationUtrs={integrationUtrs}
            />
          ))}
        </>
      );
    }
    case CalculationType.Stages:
    default: {
      return <div>Not support yet</div>;
    }
  }
};

interface ConnectionProps {
  calculation: CalculationWithUtrInfo;
  name: string | undefined;
  unitDesc: string;
  utrs: CalculationUtr[];
  integrationUtrs: CalculationIntegrationUtr[];
  selectedConnection?: SelectedConnection;
  onClickConnection: () => void;
  onPopulateInput: () => void;
  displayDetails?: boolean;
}


const DetailsTooltip = (props: ConnectionProps) => {
  const { calculation, displayDetails } = props;
  if (!displayDetails || calculation.type !== CalculationType.Direct) {
    return null;
  }

  const variable = calculation.variables[calculation.direct];
  if (!variable) {
    return null;
  }

  const utr = props.utrs.find((utr) => utr.code === variable.utrCode);
  if (!utr) {
    return null;
  }

  const tooltip = (
    <div className={'text-left'}>
      <span>Direct Calculation: {calculation.name}</span><br />
      {calculation.description ? (<div>{calculation.description}</div>) : null}
      <span key={variable.utrCode}>
        <h6 className={'text-ThemeTextMedium mb-0'}>Direct Universal Tracker</h6>
        <span>code: {variable.utrCode}</span><br />
        <span>_id: {utr._id}</span><br />
        {variable.valueListCode ? `#${variable.valueListCode} ` : ' '}
        {variable.integrationCode ? `(${variable.integrationCode})` : ''}
        </span>
    </div>
  );

  return (
    <SimpleTooltip text={tooltip}>
      <i className={'fa-light fa-info-circle text-ThemeIconSecondary cursor-pointer'}/>
    </SimpleTooltip>
  )
}


const Connection = (props: ConnectionProps) => {
  const {
    calculation,
    name,
    unitDesc,
    utrs,
    integrationUtrs,
    selectedConnection,
    onClickConnection,
    onPopulateInput,
  } = props;
  const value = calculation.data?.value ?? 0;
  const numberScale = calculation.data?.numberScale ?? '';

  return (
    <div
      className={classNames('p-1', {
        'cursor-pointer': calculation.valueListCode,
        'background-ThemeAccentExtralight': selectedConnection?._id === calculation._id,
      })}
      onClick={onClickConnection}
    >
      {name ? <label className='text-ThemeTextMedium mb-2'>
        CONNECTION {name} <DetailsTooltip {...props}/>
      </label> : null}
      <div key={calculation._id}>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='text-sm me-auto my-0 text-ThemeTextMedium'>Answer:</label>
          <p className='mb-0 text-ThemeTextDark fw-bold'>
            {value} {numberScale} {unitDesc}
          </p>
          <i
            className='fa-light fa-arrow-right-to-arc ms-2 text-ThemeAccentMedium cursor-pointer'
            onClick={onPopulateInput}
          />
        </div>
        <Variables calculation={calculation} utrs={utrs} integrationUtrs={integrationUtrs} />
      </div>
    </div>
  );
};

const WarningModal = ({
  open,
  toggle,
  handleConfirm,
}: {
  open: boolean;
  toggle: () => void;
  handleConfirm: () => void;
}) => {
  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>
        <span className='text-ThemeWarningExtraDark'>Replace answer?</span>
      </ModalHeader>
      <ModalBody>
        <p className='mb-0'>
          This field already has a value/content in it. If you proceed this value will be replaced with the answer you
          have selected in the sidebar.
        </p>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='transparent' onClick={toggle}>
          Cancel
        </Button>
        <Button color='warning' onClick={handleConfirm}>
          Replace answer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface Props {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation' | 'unitType'>;
  utrv: SurveyModelMinimalUtrv;
}

export const Connections = ({ utr, utrv }: Props) => {
  const getSecondaryConnectionsQuery = useGetSecondaryConnectionsQuery({ utrvId: utrv._id });
  const isStaffUser = useAppSelector(isStaff)

  const { connection, setConnection, setInputData } = useConnectionContext();
  const [showWarning, toggleShowWarning] = useToggle(false);

  const handlePopulateInput = (calculation: CalculationWithUtrInfo) => {
    setConnection(calculation);
    if (!calculation) {
      return;
    }

    const data = calculation.data;
    if (data === undefined) {
      return;
    }

    const currentValue = getInputValue({ utr, utrv, valueListCode: calculation.valueListCode });
    const convertedCalculationValue = getConvertedCalculationValue({
      calculationData: data,
      utr,
      utrv,
      valueListCode: calculation.valueListCode,
    });

    if (currentValue === convertedCalculationValue) {
      return;
    }

    if (currentValue === undefined) {
      setInputData(convertedCalculationValue);
      return;
    }

    toggleShowWarning();
  };

  const handleConfirm = () => {
    if (!connection?.data) {
      setInputData(undefined);
      toggleShowWarning();
      return;
    }

    const convertedCalculationValue = getConvertedCalculationValue({
      calculationData: connection.data,
      utr,
      utrv,
      valueListCode: connection.valueListCode,
    });
    setInputData(convertedCalculationValue);
    toggleShowWarning();
  };

  const onSuccessRender = ({
    connections,
    utrs,
    integrationUtrs = [],
  }: {
    connections: UniversalTrackerConnection[];
    utrs: CalculationUtr[];
    integrationUtrs?: CalculationIntegrationUtr[];
  }) => {
    const calculations = getCalculations(connections);

    if (!calculations.length) {
      return <p>This {QUESTION.SINGULAR} has no connections</p>;
    }

    return (
      <>
        <p className='text-sm text-ThemeTextLight'>
          Click <i className='fa-light fa-arrow-right-to-arc' /> to populate input field with selected answer
        </p>
        {calculations.map((calculation, index, calculations) => {
          const unitText = calculation.data?.unit
            ? getUnitText({ valueType: utr.valueType, unit: calculation.data.unit, unitType: utr.unitType })
            : getMetricUnitDesc(utr, calculation.valueListCode, '');
          return (
            <React.Fragment key={calculation._id}>
              {index ? <DashboardDivider className='my-3' /> : null}
              <Connection
                displayDetails={isStaffUser}
                calculation={calculation}
                name={calculations.length === 1 ? undefined : String(index + 1)}
                unitDesc={unitText}
                utrs={utrs}
                integrationUtrs={integrationUtrs}
                selectedConnection={connection}
                onClickConnection={() => setConnection(calculation)}
                onPopulateInput={() => handlePopulateInput(calculation)}
              />
            </React.Fragment>
          );
        })}
        <WarningModal open={showWarning} toggle={toggleShowWarning} handleConfirm={handleConfirm} />
      </>
    );
  };

  return (
    <div>
      <QueryWrapper query={getSecondaryConnectionsQuery} onSuccess={onSuccessRender} />
    </div>
  );
};
