import Select, { Props as BaseSelectProps, GroupBase, OptionProps, components } from 'react-select';
import { DropdownIndicator, HiddenDropdownIndicator, ClearIndicator, Option, getStyles, SelectStyleProps } from './SelectFactory';
import { SimpleTooltip } from '../simple-tooltip';

export type SelectProps<T = string | null> = BaseSelectProps<Option<T> | null, false, GroupBase<Option<T> | null>> &
  SelectStyleProps;

const CustomOption = <T = string | null>(props: OptionProps<Option<T> | null, false, GroupBase<Option<T> | null>>) => {
  const { tooltip, id } = props.data ?? {};
  const componentWithId = id ? (
    <div id={id}>
      <components.Option {...props} />
    </div>
  ) : (
    <components.Option {...props} />
  );

  return tooltip ? (
    <SimpleTooltip placement='bottom' text={tooltip}>
      {componentWithId}
    </SimpleTooltip>
  ) : (
    componentWithId
  );
};

export const SingleSelect = <T = string | null>(props: SelectProps<T>) => {
  const {
    components,
    styles,
    isTransparent,
    isFlexibleSize,
    showDropdownIndicator = true,
    isMenuPortalTargetBody = false,
    controlHoverBackgroundColor,
    minWidth,
    ...rest
  } = props;
  return (
    <Select
      components={{
        DropdownIndicator: showDropdownIndicator ? DropdownIndicator : HiddenDropdownIndicator,
        ClearIndicator,
        Option: CustomOption,
        ...components,
      }}
      styles={{
        ...getStyles<Option<T> | null>({
          active: Boolean(!props.isDisabled),
          isMulti: false,
          isTransparent,
          isFlexibleSize,
          isMenuPortalTargetBody,
          controlHoverBackgroundColor,
          minWidth,
        }),
        ...styles,
      }}
      menuPortalTarget={isMenuPortalTargetBody ? document.body : null}
      {...rest}
    />
  );
};
