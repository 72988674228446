import { SelectOption } from '@g17eco/types/select';
import { GroupBase, StylesConfig, components, ClearIndicatorProps } from 'react-select';
import { CreatableSelect, CreatableSelectProps } from './CreatableSelect';
import { LabelSelect, LabelSelectProps } from './LabelSelect';
import { MultipleSelect, MultipleSelectProps } from './MultipleSelect';
import { SelectProps, SingleSelect } from './SingleSelect';
import { CreatableMultipleSelect, CreatableMultipleSelectProps } from './CreatableMultipleSelect';

export enum SelectTypes {
  LabelSelect = 'labelSelect',
  SingleSelect = 'singleSelect',
  MultipleSelect = 'multipleSelect',
  CreatableSelect = 'creatableSelect',
  CreatableMultipleSelect = 'creatableMultipleSelect',
}

export type Option<T = string | null> = Pick<SelectOption<T>, 'label' | 'searchString' | 'value'> & {
  isDisabled?: boolean;
  tooltip?: string | JSX.Element;
  id?: string;
};

type ExtendedMultipleSelectProps<T = string | null> = MultipleSelectProps<T> & {
  selectType: SelectTypes.MultipleSelect;
};

type ExtendedSingleSelectProps<T = string | null> = SelectProps<T> & {
  selectType: SelectTypes.SingleSelect;
};

type ExtendedLabelSelectProps = LabelSelectProps & {
  selectType: SelectTypes.LabelSelect;
};

type ExtendedCreatableSelectProps<T = string | null> = CreatableSelectProps<T> & {
  selectType: SelectTypes.CreatableSelect;
};

type ExtendedCreatableMultipleSelectProps<T = string | null> = CreatableMultipleSelectProps<T> & {
  selectType: SelectTypes.CreatableMultipleSelect;
};

type Props<T = string | null> = (
  | ExtendedMultipleSelectProps<T>
  | ExtendedSingleSelectProps<T>
  | ExtendedLabelSelectProps
  | ExtendedCreatableSelectProps<T>
  | ExtendedCreatableMultipleSelectProps<T>
) & { key?: React.Key | null };

export interface SelectStyleProps {
  isTransparent?: boolean;
  isFlexibleSize?: boolean;
  showDropdownIndicator?: boolean;
  isMenuPortalTargetBody?: boolean;
  controlHoverBackgroundColor?: string;
  minWidth?: number;
}

export const DropdownIndicator = () => <i className='fa-light fa-caret-down mx-3 text-lg text-ThemeIconSecondary' />;

export const HiddenDropdownIndicator = () => <span className='mx-1'></span>;

export const ClearIndicator = <T = string | null>(props: ClearIndicatorProps<T>) => {
  return (
    <components.ClearIndicator {...props}>
      <i className='fal fa-xmark p-0 ml-3 text-md' />
    </components.ClearIndicator>
  );
};

export const SelectFactory = <T = string | null>(props: Props<T>) => {
  switch (props.selectType) {
    case SelectTypes.MultipleSelect:
      return <MultipleSelect {...props} />;
    case SelectTypes.SingleSelect:
      return <SingleSelect {...props} />;
    case SelectTypes.LabelSelect:
      return <LabelSelect {...props} />;
    case SelectTypes.CreatableSelect:
      return <CreatableSelect {...props} />;
    case SelectTypes.CreatableMultipleSelect:
      return <CreatableMultipleSelect {...props} />;
    default:
      return <></>;
  }
};

export const getColor = ({ active }: { active: boolean }) =>
  active ? 'var(--theme-TextDark)' : 'var(--theme-NeutralsExtradark)';

export const getStyles = <T = Option | null>({
  active,
  isMulti,
  isTransparent = false,
  isFlexibleSize = false,
  showSelectAll = true,
  isMenuPortalTargetBody = false,
  controlHoverBackgroundColor,
  minWidth = 200,
}: {
  active: boolean;
  isMulti: boolean;
  isTransparent?: boolean;
  isFlexibleSize?: boolean;
  showSelectAll?: boolean;
  isMenuPortalTargetBody?: boolean;
  controlHoverBackgroundColor?: string;
  minWidth?: number;
}): StylesConfig<T, boolean, GroupBase<T>> => {
  const border = 'var(--theme-NeutralsLight)';
  const color = active ? 'var(--theme-TextDark)' : 'var(--theme-TextMedium)';
  const indicatorColor = getColor({ active });

  return {
    container: (provided) => ({
      ...provided,
      ...(isFlexibleSize ? { height: 'inherit' } : {}),
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: isFlexibleSize ? 'inherit' : 35,
      height: isFlexibleSize ? 'inherit' : 35,
      minWidth: isFlexibleSize ? 'inherit' : `${minWidth}px`,
      boxShadow: 'none',
      fontWeight: 'normal',
      '&:hover': {
        borderColor: border,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: controlHoverBackgroundColor ?? 'transparent',
      },
      borderColor: state.isFocused ? border : isTransparent ? 'transparent' : border,
      ...(isTransparent ? { backgroundColor: 'transparent' } : {}),
      ...(isFlexibleSize ? { padding: '2px 0' } : {}),
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      minWidth: isMulti ? 'fit-content' : isFlexibleSize ? 'max-content' : `${minWidth}px`,
      whiteSpace: 'normal',
      border: 'solid 1px var(--theme-NeutralsLight)',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
      paddingBottom: 4,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuPortal: (provided) => ({ ...provided, ...(isMenuPortalTargetBody ? { zIndex: 1050 } : {}) }),
    placeholder: (provided) => ({
      ...provided,
      color,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
      color: 'var(--theme-IconSecondary)',
      '&:hover': {
        color: 'var(--theme-TextDark)',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      marginRight: '10px',
      color: indicatorColor,
      '&:hover': {
        color: indicatorColor,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingRight: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      },
      ...(isMulti
        ? {
            ...(showSelectAll
              ? {
                  '&:first-of-type': {
                    borderBottom: '2px solid var(--theme-NeutralsLight)',
                  },
                }
              : {}), // used to distinguish between select all and other options
            backgroundColor: 'white',
            color: 'var(--theme-TextDark)',
          }
        : {}),
      '&[aria-disabled="true"]': {
        // styles for disabled options
        input: {
          backgroundColor: getColor({ active: false }),
        },
        color: getColor({ active: false }),
      },
      i: {
        color: 'var(--theme-IconSecondary)',
      },
      img: {
        opacity: state.isDisabled ? 0.5 : 1,
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'var(--theme-AccentMedium)',
      color: 'white',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
      whiteSpace: 'normal',
      fontSize: '0.8125rem !important',
    }),
    multiValueRemove: (base) => ({
      ...base,
      minWidth: '22px',
    }),
  };
};
