import { useEffect } from 'react';
import { ButtonGradient } from '../../apps/common/components/ButtonGradient/ButtonGradient';
import { useToggle } from '@hooks/useToggle';
import { AssistantOffcanvas } from './AssistantOffcanvas';
import { FeatureStability } from '@g17eco/molecules';
import { useConnectionContext } from './connections/ConnectionContext';
import { AnswerProps } from './ai-assistant/types';

export const AssistantBtn = ({ utr, utrv, table }: AnswerProps) => {
  const [open, toggle] = useToggle(false);

  const { removeConnection } = useConnectionContext();
  
  useEffect(() => {
    if (document) {
      const questionContainerElement = document.getElementById('question-container');
      if (open) {
        questionContainerElement?.classList?.add('has-sidebar-left');
      } else {
        questionContainerElement?.classList?.remove('has-sidebar-left');
      }
    }
  }, [open]);
  
  const handleToggle = () => {
    removeConnection();
    toggle();
  };

  return (
    <>
      <ButtonGradient outline onClick={handleToggle} key={`assistant-btn-${open ? 'open' : 'close'}`}>
        <i className='fal fa-sparkles mr-2' />
        Assistant
        <FeatureStability stability={'beta'} />
      </ButtonGradient>
      <AssistantOffcanvas
        utrv={utrv}
        utr={utr}
        table={table}
        open={open}
        toggle={handleToggle}
      />
    </>
  );
};
