import { SURVEY } from '@constants/terminology';
import { QuestionSelectingFilter } from '../../question-selecting-filter/QuestionSelectingFilter';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { UtrValueType } from '@g17eco/types/universalTracker';

interface Props {
  initiativeId: string;
  handleSelectQuestion: (data: QuestionData) => void;
  questionData?: QuestionData;
}

export const MetricText = ({ initiativeId, handleSelectQuestion, questionData }: Props) => {
  return (
    <>
      <p className='mt-3 text-medium'>Select data-point from {SURVEY.SINGULAR} scope</p>
      <QuestionSelectingFilter
        questionData={questionData}
        initiativeId={initiativeId}
        handleSelect={handleSelectQuestion}
        validatingFunc={(question) => question.valueType === UtrValueType.Text}
      />
    </>
  );
};
