import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { ConnectionUtrv } from '../types/universalTrackerValue';
import { Connection } from '../types/universalTracker';
import { transformResponse } from './g17ecoApi';
import { TKeyValue } from '@g17eco/types/file';
import { UniversalTrackerConnection, CalculationUtr, CalculationIntegrationUtr } from '@g17eco/types/utrv-connections';

interface ConnectionsData {
  connections: Connection[];
  utrvs: ConnectionUtrv[];
}
const UTRV_CONNECTIONS_TAG = 'utrv-connections';

// Define a service using a base URL and expected endpoints
export const utrvApi = createApi({
  reducerPath: 'relatedApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['utrv-related', 'target-baseline', UTRV_CONNECTIONS_TAG],
  endpoints: (builder) => ({
    getConnections: builder.query<ConnectionsData, string>({
      transformResponse: (response: ApiResponse<ConnectionsData>) => response.data,
      query: (utrvId) => ({
        url: `/universal-tracker-values/${utrvId}/connections`,
        method: 'get',
      }),
    }),
    updateTargetBaseline: builder.mutation<string, TKeyValue & { utrvId: string }>({
      transformResponse,
      query: ({ utrvId, ...data }) => ({
        url: `/universal-tracker-values/${utrvId}/update-target-baseline`,
        method: 'patch',
        data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'target-baseline', id: arg.utrvId }],
    }),
    getSecondaryConnections: builder.query<
      {
        connections: UniversalTrackerConnection[];
        utrs: CalculationUtr[];
        integrationUtrs?: CalculationIntegrationUtr[];
      },
      { utrvId: string }
    >({
      transformResponse,
      query: ({ utrvId }) => ({
        url: `/universal-tracker-values/${utrvId}/secondary-connections`,
        method: 'get',
      }),
      providesTags: (_result, _error, agrs) => [{ type: UTRV_CONNECTIONS_TAG, id: agrs.utrvId }],
    }),
  }),
});

export const { useGetConnectionsQuery, useUpdateTargetBaselineMutation, useGetSecondaryConnectionsQuery } = utrvApi;
