import { useEffect, useRef } from 'react';
import { Input } from 'reactstrap';

export enum CheckboxState {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

interface CheckboxProps {
  status: CheckboxState;
  [k: string]: any;
}

export const Checkbox = (props: CheckboxProps) => {
  const { status, ...otherProps } = props;
  const checkRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!checkRef.current) {
      return;
    }

    checkRef.current.checked = status === CheckboxState.Checked;
    checkRef.current.indeterminate = status === CheckboxState.Indeterminate;
  }, [status]);

  return <Input type='checkbox' innerRef={checkRef as React.Ref<HTMLInputElement>} {...otherProps} />;
};
