import { createContext, useContext, useState } from 'react';
import { CalculationWithUtrInfo } from './types';

export type SelectedConnection = CalculationWithUtrInfo | undefined;
type InputData = number | undefined;

interface ConnectionContextProps {
  connection: SelectedConnection;
  setConnection: (connection: SelectedConnection) => void;
  setInputData: (data: InputData) => void;
  inputData?: InputData;
  removeConnection: () => void;
}

export const ConnectionContext = createContext<ConnectionContextProps>({
  setConnection: (_connection: SelectedConnection) => {},
  connection: undefined,
  setInputData: (_data: InputData) => {},
  inputData: undefined,
  removeConnection: () => {},
});

export const useConnectionContext = () => {
  return useContext(ConnectionContext);
};

interface Props {
  children: JSX.Element;
}

export const ConnectionProvider = ({ children }: Props) => {
  const [connection, setConnection] = useState<SelectedConnection>();
  const [inputData, setInputData] = useState<InputData>();

  const removeConnection = () => {
    setConnection(undefined);
    setInputData(undefined);
  };

  return (
    <ConnectionContext.Provider
      value={{
        connection,
        setConnection: (connection: SelectedConnection) => setConnection(connection),
        setInputData: (data: InputData) => setInputData(data),
        inputData,
        removeConnection,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};
