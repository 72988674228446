import { CalculationData, CalculationUtrv, UniversalTrackerConnection } from "@g17eco/types/utrv-connections";
import { CalculationWithUtrInfo } from "./types";
import { UniversalTrackerPlain, UtrValueType } from "@g17eco/types/universalTracker";
import { SurveyModelMinimalUtrv } from "@models/surveyData";
import { convertUnitNumberScale } from "@utils/units";
import { getNumberScale, getTableInputData, getUnitCode } from "@utils/universalTrackerValue";

// For now, we treat all calculations as connections to be simply displayed. Might extend this in the future.
export const getCalculations = (connections: UniversalTrackerConnection[]) => {
  const calculations: CalculationWithUtrInfo[] = [];
  const dataMap = new Map<string, CalculationData[]>();

  connections.forEach((connection) => {
    const { utrCode, valueListCode, calculationGroups } = connection;
    const key = `${utrCode}-${valueListCode}`;

    calculationGroups.forEach((calculationGroup) => {
      calculationGroup.calculations.forEach((calculation) => {
        // Show calculations that have data and filter out one that has duplicate data.
        const dataList = dataMap.get(key) ?? [];
        const hasDupicatedData = dataList.some(
          (item) =>
            calculation.data?.value === item.value &&
            calculation.data?.unit === item.unit &&
            calculation.data?.numberScale === item.numberScale
        );
        if (calculation.data?.value && !hasDupicatedData) {
          dataMap.set(key, [...dataList, calculation.data]);
          calculations.push({ ...calculation, utrCode, valueListCode });
        }
      });
    });
  });

  return calculations;
};

type GetInputValueParams = {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation'>;
  utrv: SurveyModelMinimalUtrv;
  valueListCode?: string;
}

export const getInputValue = ({
  utrv,
  utr,
  valueListCode,
}: GetInputValueParams) => {
  switch (utr.valueType) {
    case UtrValueType.Table: {
      const tableData = utrv.valueData?.table;
      if (!valueListCode || !tableData?.length || utr.valueValidation?.table?.validation?.maxRows !== 1) {
        return undefined;
      }

      const value = tableData[0]?.find((col) => col.code === valueListCode)?.value;
      return value === undefined ? undefined : Number(value);
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return utrv.value;
    }
    default: {
      return undefined;
    }
  }
};

type GetConvertedCalculationValueParams = {
  utr: Pick<UniversalTrackerPlain, 'valueType' | 'targetDirection' | 'valueValidation' | 'numberScale' | 'unit'>;
  utrv: CalculationUtrv;
  valueListCode?: string;
  calculationData: CalculationData;
};
export const getConvertedCalculationValue = ({
  calculationData,
  utr,
  utrv,
  valueListCode,
}: GetConvertedCalculationValueParams) => {
  const { value = 0, unit: calculationUnit, numberScale: calculationNumberScale = 'single' } = calculationData;

  switch (utr.valueType) {
    case UtrValueType.Table: {
      // Convert with same numberScale and unit
      if (!valueListCode) {
        return calculationData.value;
      }
      // Populate is available to single row table only
      const table = getTableInputData(utrv);
      const inputColumn = table?.[0]?.find((col) => col.code === valueListCode);
      const defaultColumn = utr.valueValidation?.table?.columns?.find((col) => col.code === valueListCode);

      const numberScaleInput = inputColumn?.numberScale ?? defaultColumn?.numberScale ?? 'single';
      const unitInput = inputColumn?.unit ?? defaultColumn?.unit;

      return convertUnitNumberScale({
        value,
        from: { unit: calculationUnit, numberScale: calculationNumberScale },
        to: { unit: unitInput, numberScale: numberScaleInput },
      });
    }
    case UtrValueType.Number:
    case UtrValueType.Percentage: {
      return convertUnitNumberScale({
        value,
        from: { unit: calculationUnit, numberScale: calculationNumberScale },
        to: { unit: getUnitCode(utrv, utr), numberScale: getNumberScale(utrv, utr) },
      });
    }
    default:
      return value;
  }
}