import { ValueStages } from '@services/aggregation/ValueStage';
import { UniversalTrackerPlain, UtrType } from './universalTracker';
import { GeneratedUtr, IntegrationProvider } from './integration';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { UniversalTrackerValuePlain } from './surveyScope';

export enum CalculationType {
  Direct = 'direct', // 'a'
  Formula = 'formula', // {a}
  Stages = 'stages', // JSONata
}
export interface VariableInfo {
  utrCode: string;
  valueListCode?: string;
  /**
   * Integration service provider code
   */
  integrationCode?: string;
}

type CalculationVariableGroup = {
  name: string
  colour?: string,
  link?: string,
  icon?: string;
};

export type CalculationData = {
  value: number | undefined;
  unit: string | undefined;
  numberScale: string | undefined;
};

interface CalculationBase {
  // Common properties for all types
  _id: string;
  name: string;
  description?: string;
  variables: Record<string, VariableInfo>;
  data: CalculationData;

  // Custom resolved group
  group?: CalculationVariableGroup

  type: CalculationType;
}

interface DirectCalculation extends CalculationBase {
  type: CalculationType.Direct;
  direct: string;
}

interface FormulaCalculation extends CalculationBase {
  type: CalculationType.Formula;
  formula: string;
}

interface StagesCalculation extends CalculationBase {
  type: CalculationType.Stages;
  stages: ValueStages;
}

export type Calculation = DirectCalculation | FormulaCalculation | StagesCalculation;

interface CalculationGroup {
  _id: string;
  /** internal name like "Number of female employees" */
  name: string;
  /** Explain where it can be used */
  description?: string;

  calculations: Calculation[];
}

export interface UniversalTrackerConnection {
  _id: string;
  utrCode: string;
  // If targeting column
  valueListCode?: string;

  calculationGroups: CalculationGroup[];
}

export type CalculationUtr = Pick<UniversalTrackerPlain, '_id' | 'code' | 'valueLabel' | 'valueValidation' | 'type'>;
export type CalculationIntegrationUtr = GeneratedUtr & {
  type: UtrType.Generated,
  integrationCode: string;
  provider?: Pick<IntegrationProvider, 'shortName' | 'logo' | 'icon'>;
};

export type CalculationUtrv = SurveyModelMinimalUtrv & Pick<UniversalTrackerValuePlain, 'unit' | 'numberScale'>;
