import { Button } from 'reactstrap';
import { TagItem } from '@components/survey/question/tags/TagItem';
import { getInitialState } from '@components/utr-decimal/utils';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { Checkbox, CheckboxState } from '@components/common/Checkbox';
import { InitiativeQuestionManagementResponse } from '@api/admin-dashboard';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { UniversalTrackerBlueprintMin, UtrValueType } from '@g17eco/types/universalTracker';
import { QUESTION } from '@constants/terminology';
import {
  InputOverrideFormType,
  QuestionConfigurationDropdown,
} from '@features/question-configuration';
import { ColumnDef, PopoverButton, SimpleTooltip, Table } from '@g17eco/molecules';
import {
  ConfigurationType,
  GLOBAL_UPDATE_CODE,
  hasOverriddenUtrvConfig,
  iconMap,
} from '@features/question-configuration/utils';
import { UtrvConfigCode, UtrvConfigValue } from '@features/question-configuration/metric-override/contants';
import { useContext } from 'react';
import { QuestionManagementContext } from './QuestionManagementContainer';

export enum QuestionTableColumn {
  Tags = 'Tags',
  Decimals = 'Decimals',
  NumberScale = 'Number scale',
  Unit = 'Unit type',
  Overrides = 'Overrides',
  MultiSelectAction = 'Multiple select action',
  SingleAction = 'Single action',
}

interface QuestionTableProps {
  data: InitiativeQuestionManagementResponse['utrs'];
  utrTagMap: InitiativeQuestionManagementResponse['utrTagMap'];
  rootInitiativeUtrMap: Map<string, InitiativeUniversalTracker>;
  toggleSelectAll: () => void;
  selectedQuestions: BulkActionUtr[];
  toggleSelectQuestion: (utr: BulkActionUtr, status: CheckboxState) => void;
  isSelectAll: boolean;
  isRootOrganization: boolean;
  pageSize?: number;
  hiddenColumns?: QuestionTableColumn[];
  hiddenOptions?: ConfigurationType[];
  configureSelectedQuestion: (params: { utr: BulkActionUtr; type: ConfigurationType }) => void;
}

export const QuestionTable = (props: QuestionTableProps) => {
  const {
    data,
    utrTagMap,
    rootInitiativeUtrMap,
    selectedQuestions,
    toggleSelectAll,
    toggleSelectQuestion,
    isSelectAll = false,
    pageSize,
    hiddenColumns = [],
    hiddenOptions = [],
    configureSelectedQuestion,
  } = props;

  const { setInputOverrideType, setUtrvConfigCode } = useContext(QuestionManagementContext);
  const isQuestionSelected = (utrId: string) => selectedQuestions.some((utr) => utr._id === utrId);

  const columns: ColumnDef<UniversalTrackerBlueprintMin>[] = [
    {
      header: QUESTION.CAPITALIZED_SINGULAR,
      accessorKey: 'name',
    },
  ];

  if (!hiddenColumns.includes(QuestionTableColumn.Tags)) {
    columns.push({
      id: 'tags',
      header: 'Tags',
      cell: (c) => {
        const tags = utrTagMap[c.row.original._id];
        return tags && tags.length
          ? tags.map((tag: string) => {
              return <TagItem key={tag} groupName={tag} />;
            })
          : '-';
      },
      enableSorting: false,
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.Decimals)) {
    columns.push({
      id: 'decimals',
      header: 'Decimals',
      meta: {
        cellProps: { style: { width: 20 } },
      },
      cell: (c) => {
        const rootInitiativeUtr = rootInitiativeUtrMap.get(c.row.original._id);
        if (!rootInitiativeUtr) {
          return '-';
        }

        const defaultDecimal = getInitialState({
          inputType: InputOverrideFormType.SingleInput,
          tableColumns: [],
          valueValidation: rootInitiativeUtr.valueValidation,
        });

        return defaultDecimal[GLOBAL_UPDATE_CODE].value ?? '-';
      },
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.NumberScale)) {
    columns.push({
      id: 'numScale',
      header: 'NumScale',
      cell: (c) => {
        return getDisplayedUnitAndNumScale(c.row.original, rootInitiativeUtrMap).numberScale;
      },
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.Unit)) {
    columns.push({
      id: 'unit',
      header: 'Unit',
      cell: (c) => {
        return getDisplayedUnitAndNumScale(c.row.original, rootInitiativeUtrMap).unit;
      },
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.Overrides)) {
    columns.push({
      id: 'overrides',
      header: 'Overrides',
      cell: (c) => {
        return getDisplayedOverrides(c.row.original, rootInitiativeUtrMap);
      },
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.MultiSelectAction)) {
    columns.push({
      id: 'multi-select-action',
      meta: {
        cellProps: { style: { width: 20 }, className: 'text-center' },
      },
      header: () => (
        <Button color='link' onClick={toggleSelectAll}>
          {isSelectAll ? 'Unselect all' : 'Select all'}
        </Button>
      ),
      cell: (c) => {
        const question = c.row.original;
        const status = isQuestionSelected(question._id) ? CheckboxState.Checked : CheckboxState.Unchecked;
        return <Checkbox status={status} onChange={() => toggleSelectQuestion(c.row.original, status)} />;
      },
      enableSorting: false,
    });
  }

  if (!hiddenColumns.includes(QuestionTableColumn.SingleAction)) {
    columns.push({
      id: 'single-action',
      meta: {
        cellProps: { style: { width: 20 }, className: 'text-center' },
      },
      header: '',
      cell: (c) => {
        return (
          <div className='d-flex align-items-center gap-2'>
            <QuestionConfigurationDropdown
              selectedQuestions={[c.row.original]}
              hiddenOptions={hiddenOptions}
              changeConfigType={(configType) => {
                configureSelectedQuestion({ utr: c.row.original, type: configType });
                setInputOverrideType(undefined);
                setUtrvConfigCode(undefined);
              }}
            />
          </div>
        );
      },
      enableSorting: false,
    });
  }

  return <Table data={data} columns={columns} pageSize={pageSize ?? data.length} responsive />;
};

const getTableUnitAndNumScale = ({
  utr,
  initiativeUtr,
  prop,
}: {
  utr: BulkActionUtr;
  initiativeUtr: InitiativeUniversalTracker | undefined;
  prop: 'unitInput' | 'numberScaleInput';
}) => {
  const columns = utr.valueValidation?.table?.columns;
  return columns ? (
    <PopoverButton id={`${utr._id}-unit`} title='Unit'>
      {columns.map((col) => {
        const initiativeUtrColumn = initiativeUtr?.valueValidation?.table?.columns.find((c) => c.code === col.code);
        return (
          <div key={col.code} className='d-flex align-items-center justify-content-between gap-3 py-1'>
            <div>{col.name}</div>
            <div>{initiativeUtrColumn?.[prop] ?? '-'}</div>
          </div>
        );
      })}
    </PopoverButton>
  ) : (
    '-'
  );
};

const getDisplayedUnitAndNumScale = (
  utr: BulkActionUtr,
  rootInitiativeUtrMap: Map<string, InitiativeUniversalTracker>
) => {
  switch (utr.valueType) {
    case UtrValueType.Percentage:
    case UtrValueType.NumericValueList:
    case UtrValueType.Number: {
      const initiativeUtr = rootInitiativeUtrMap.get(utr._id);
      const unitInput = initiativeUtr?.unitInput ?? '-';
      const numberScaleInput = initiativeUtr?.numberScaleInput ?? '-';
      return {
        unit: <span>{unitInput}</span>,
        numberScale: <span>{numberScaleInput}</span>,
      };
    }
    case UtrValueType.Table: {
      const initiativeUtr = rootInitiativeUtrMap.get(utr._id);
      return {
        unit: getTableUnitAndNumScale({ utr, initiativeUtr, prop: 'unitInput' }),
        numberScale: getTableUnitAndNumScale({ utr, initiativeUtr, prop: 'numberScaleInput' }),
      };
    }
    default:
      return {
        unit: '-',
        numberScale: '-',
      };
  }
};

const getIcon = ({ initiativeUtr, field }: { initiativeUtr: InitiativeUniversalTracker; field: UtrvConfigCode }) => {
  if (!hasOverriddenUtrvConfig({ initiativeUtr, field })) {
    return null;
  }
  const fieldValue = initiativeUtr.utrvConfig?.[field];
  if (fieldValue === UtrvConfigValue.Default) {
    return null;
  }
  const isRequired = fieldValue === UtrvConfigValue.Required;
  return (
    <SimpleTooltip text={`${iconMap[field].label} ${isRequired ? 'required' : 'optional'}`}>
      <i
        className={`${isRequired ? iconMap[field].requiredIcon : iconMap[field].optionalIcon} text-ThemeIconSecondary`}
      ></i>
    </SimpleTooltip>
  );
};

const getDisplayedOverrides = (utr: BulkActionUtr, rootInitiativeUtrMap: Map<string, InitiativeUniversalTracker>) => {
  const initiativeUtr = rootInitiativeUtrMap.get(utr._id);
  if (!initiativeUtr) {
    return '-';
  }
  return (
    <div className='d-flex align-items-center gap-2'>
      {getIcon({ initiativeUtr, field: 'verificationRequired' })}
      {getIcon({ initiativeUtr, field: 'noteRequired' })}
      {getIcon({ initiativeUtr, field: 'isPrivate' })}
      {getIcon({ initiativeUtr, field: 'evidenceRequired' })}
    </div>
  );
};
