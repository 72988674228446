import { getGroup } from '@g17eco/core';
import { Option } from '@g17eco/molecules';
import { MetricGroup } from '@g17eco/types/metricGroup';
import { UniversalTrackerBlueprintMin, UtrValueType } from '@g17eco/types/universalTracker';

// types that doesn't include value list, table.
export const SIMPLE_TYPES: string[] = [
  UtrValueType.Number,
  UtrValueType.Percentage,
  UtrValueType.Date,
  UtrValueType.Text,
  UtrValueType.Sample,
];

export enum MetaAction {
  Select = 'select-option',
  Clear = 'clear',
}

export const getQuestionOptionLabel = ({ label, isAllowed }: { label: string; isAllowed: boolean }) => {
  if (isAllowed) {
    return label;
  }
  return (
    <div className='d-flex align-items-center justify-content-between gap-2'>
      <div className='text-truncate'>{label}</div>
      <div className='text-xs text-nowrap text-ThemeDangerExtradark'>Not supported</div>
    </div>
  );
};

export const getSelectingPack = ({
  utr,
  packs,
  metricGroups,
}: {
  utr?: UniversalTrackerBlueprintMin;
  packs: Option[];
  metricGroups: MetricGroup[];
}) => {
  if (!utr) {
    return { groupCode: '', subGroupCode: '' };
  }
  const groupCode = packs.find((p) => p.value === utr.type)?.value;
  if (!groupCode) {
    const customGroup = metricGroups.find(
      (group) => group.universalTrackers?.some((customMetricId) => customMetricId === utr._id)
    );
    return { groupCode: customGroup?._id ?? '', subGroupCode: '' };
  }
  const subGroups = getGroup('standards-and-frameworks', groupCode)?.subgroups ?? [];
  const subGroupCode = subGroups.find((s) => utr.typeTags?.includes(s.code))?.code ?? '';
  return { groupCode, subGroupCode };
};
