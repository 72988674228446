import { Document, Header, ImageRun } from 'docx';
import { declarativeStyles, numberingStyles } from './styles';
import {
  heading2,
  paragraphWithBoldText,
  paragraph,
  imageWrapper,
  commentBox,
  bulletList,
  spacer,
  convertImgToBase64URL,
  getWWGLogo,
} from './document-structure';
import { ReportData } from '../../types/reportData';
import { renderQuestionGroup } from './questionView';
import { formatDate } from '../../utils/date';
import { TEXT } from './constants';
import { ReportGeneratorFn } from './types';
import UNGCLogo from '../../images/reports/ungc_logo.png';

const principles = ['ungc-one', 'ungc-two', 'ungc-three', 'ungc-four', 'ungc-five', 'ungc-six', 'ungc-seven', 'ungc-eight', 'ungc-nine', 'ungc-ten']

const getTagMap = (data: ReportData[]) => {
  return data.reduce((groupAccumulator, currentData) => {
    const UNGCPrinciples = currentData.universalTracker.tags?.ungc

    if (!Array.isArray(UNGCPrinciples)) {
      return groupAccumulator
    }

    for (const principle of principles) {
      if (UNGCPrinciples.includes(principle)) {
        groupAccumulator[principle].push(currentData)
      }
    }

    return groupAccumulator
  }, {
    'ungc-one': [],
    'ungc-two': [],
    'ungc-three': [],
    'ungc-four': [],
    'ungc-five': [],
    'ungc-six': [],
    'ungc-seven': [],
    'ungc-eight': [],
    'ungc-nine': [],
    'ungc-ten': [],
  } as { [key: string]: ReportData[] })
}

export const UNGCReportGenerator: ReportGeneratorFn<Document> = async (
  survey,
  reportData,
  downloadScope
) => {
  const wwgLogo = await getWWGLogo();

  const ungcLogoBase64 = await convertImgToBase64URL(UNGCLogo);

  const ungcLogo = new ImageRun({
    data: Uint8Array.from(atob(ungcLogoBase64), c => c.charCodeAt(0)),
    transformation: {
      width: 100,
      height: 100
    }
  });

  const { displayUserInput } = downloadScope;

  const questionMap = getTagMap(reportData);
  const periodCovered = formatDate(survey.effectiveDate, 'MMMM YYYY')

  const document = new Document({
    styles: {
      paragraphStyles: declarativeStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              imageWrapper(ungcLogo),
              imageWrapper(wwgLogo)
            ]
          })
        },
        children: [
          paragraphWithBoldText('Company Name: ', survey.initiative.name),
          paragraphWithBoldText('Company Sector: ', survey.initiative.industryText),
          paragraphWithBoldText('Report Date: ', periodCovered),
          spacer(),


          heading2('Description of actions'),
          paragraphWithBoldText('Human Rights', ''),

          paragraphWithBoldText('Principle 1: ', 'Businesses should support and respect the protection of internationally proclaimed human rights; and'),

          ...renderQuestionGroup(questionMap['ungc-one'], 'numbering-list-one', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 2: ', 'make sure that they are not complicit in human rights abuses.'),


          ...renderQuestionGroup(questionMap['ungc-two'], 'numbering-list-two', survey.unitConfig, displayUserInput),

          paragraph('Please use the box below to describe actions your company has taken in the area of human rights. Examples include:'),
          bulletList('Ensure workers are provided safe, suitable and sanitary work facilities'),
          bulletList('Protect workers from workplace harassment, including physical, verbal, sexual or psychological harassment,'),
          bulletList('abuse or threats'),
          bulletList('Take measures to eliminate ingredients, designs, defects or side-effects that could harm or threaten human life'),
          bulletList('and health during manufacturing, usage or disposal of products'),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          heading2('Labour'),
          paragraphWithBoldText('Principle 3: ', 'Businesses should uphold the freedom of association and the effective recognition of the right to collective bargaining;'),

          ...renderQuestionGroup(questionMap['ungc-three'], 'numbering-list-three', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 4: ', 'the elimination of all forms of forced and compulsory labour;'),

          ...renderQuestionGroup(questionMap['ungc-four'], 'numbering-list-four', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 5: ', 'the effective abolition of child labour; and'),

          ...renderQuestionGroup(questionMap['ungc-five'], 'numbering-list-five', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 6: ', 'the elimination of discrimination in respect of employment and occupation.'),

          ...renderQuestionGroup(questionMap['ungc-six'], 'numbering-list-six', survey.unitConfig, displayUserInput),

          paragraph('Please use the box below to describe actions your company has taken in the area of labour. Examples include:'),
          bulletList('Ensure that the company does not participate in any form of forced or bonded labour'),
          bulletList('Comply with minimum wage standards'),
          bulletList('Ensure that employment-related decisions are based on relevant and objective criteria'),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          paragraphWithBoldText('Environment', ''),
          paragraphWithBoldText('Principle 7: ', 'Businesses should support a precautionary approach to environmental challenges;'),

          ...renderQuestionGroup(questionMap['ungc-seven'], 'numbering-list-seven', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 8: ', 'undertake initiatives to promote greater environmental responsibility; and'),

          ...renderQuestionGroup(questionMap['ungc-eight'], 'numbering-list-eight', survey.unitConfig, displayUserInput),

          paragraphWithBoldText('Principle 9: ', 'encourage the development and diffusion of environmentally friendly technologies.'),

          ...renderQuestionGroup(questionMap['ungc-nine'], 'numbering-list-nine', survey.unitConfig, displayUserInput),

          paragraph('Please use the box below to describe actions your company has taken in the area of environment. Examples include:'),
          bulletList('Avoid environmental damage via regular maintenance of production processes and environmental protection system (air pollution control, waste, water treatment systems, etc.)'),
          bulletList('Ensure emergency procedures to prevent and address accidents affecting the environment and human health'),
          bulletList('Minimize the use and ensure safe handling and storage of chemical and other dangerous substances'),


          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),

          paragraphWithBoldText('Anti-Corruption', ''),
          paragraphWithBoldText('Principle 10: ', 'Businesses should work against corruption in all its forms, including extortion and bribery.'),

          ...renderQuestionGroup(questionMap['ungc-ten'], 'numbering-list-ten', survey.unitConfig, displayUserInput),

          paragraph('Please use the box below and any data from above that you have already reported on to describe actions your company has taken to fight corruption. Examples include:'),
          bulletList('Assess the risk of corruption when doing business'),
          bulletList('Mention “anti-corruption” and/or “ethical behavior” in contracts with business partners'),
          bulletList('Ensure that internal procedures support the company’s anti-corruption commitment'),

          paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
          commentBox(''),
        ]
      }],
  });

  return document;
}

